import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ src, hasInteracted }) => {
    const audioRef = useRef(null);

    useEffect(() => {
        const audioElement = audioRef.current;

        const playAudio = () => {
            if (audioElement) {
                audioElement.loop = true;
                audioElement.play().catch(error => {
                    console.error("Error playing audio:", error);
                });
            }
        };

        if (hasInteracted) {
            playAudio();
        }

        return () => {

        };
    }, [hasInteracted]); // Re-run effect when hasInteracted changes

    useEffect(() => {
        const audioElement = audioRef.current;
        if (audioElement) {
            audioElement.currentTime = 0;
            // Change audio source when src changes
            audioElement.src = src;
            audioElement.load();
            // If hasInteracted is true, play the audio with the new source
            if (hasInteracted) {
                audioElement.play().catch(error => {
                    console.error("Error playing audio:", error);
                });
            }
        }
    }, [src]); // Re-run effect when src changes

    return (
        <audio ref={audioRef} src={src} preload='auto' />
    );
};

export default AudioPlayer;
