import React, { useState, useEffect } from 'react';
import './App.css';
import './animation.css';
import CallAPI from './CallAPI';
import InputBox from './InputBox';
import AudioPlayer from './AudioPlayer';

import img from './assets/img/1.png';
import Ambience01 from './assets/audio/Ambience01.ogg';


const sendInput = (setImageSrc, setCurrentPage, currentPage) => {
    const { sendRequest } = CallAPI();

    const handleInput = async (inputValue) => {
        try {
            const responseJSON = await sendRequest(
                'https://sbug-server.fly.dev/API',
                // 'http://localhost:3000/API',

                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        input: inputValue,
                        page: currentPage
                    })
                }
            );

            if (responseJSON.valid) {

                console.log(responseJSON)
                
                if (responseJSON.fileUrl) {
                    // Fetch the image file as a Blob
                    const response = await fetch(responseJSON.fileUrl);
                    const blob = await response.blob();

                    // Create a URL for the Blob
                    const url = URL.createObjectURL(blob);

                    // Create a link element and simulate click to download
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = responseJSON.fileName || 'download';
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();

                    // Clean up by revoking the URL object
                    URL.revokeObjectURL(url);
                }
                else {
                    setImageSrc(responseJSON.imageUrl);
                    setCurrentPage(responseJSON.newPage);
                }

            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return { handleInput };
};

const Screen = ({ setHasInteracted, hasInteracted, handleAudioSrcChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [imageSrc, setImageSrc] = useState(img);
    const [currentPage, setCurrentPage] = useState("0001");
    const [animationFinished, setAnimationFinished] = useState(false);
    const [showInputBox, setShowInputBox] = useState(true); // State to control rendering of InputBox

    const { handleInput } = sendInput(setImageSrc, setCurrentPage, currentPage);

    useEffect(() => {
        if (!hasInteracted) {
            setHasInteracted(true);
            handleAudioSrcChange(Ambience01);
        }
    }, [hasInteracted, setHasInteracted, handleAudioSrcChange]);

    useEffect(() => {
        const revealImage = document.querySelector('.reveal-image');

        const handleAnimationEnd = () => {
            setAnimationFinished(true);
            revealImage.removeEventListener('animationend', handleAnimationEnd);
            setShowInputBox(true); // Mount input box after animation ends
        };

        revealImage.addEventListener('animationend', handleAnimationEnd);

        const timeoutId = setTimeout(() => {
            revealImage.classList.add('reveal-animation-delayed');
            revealImage.style.display = 'block';
        }, 5);

        return () => {
            clearTimeout(timeoutId);
            revealImage.removeEventListener('animationend', handleAnimationEnd);
        };
    }, [imageSrc]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setInputValue('');
            handleInput(inputValue);
        }
    };

    useEffect(() => {
        console.log('Current Page:', currentPage);
    }, [currentPage]);

    useEffect(() => {
        // Set showInputBox to false when image source changes
        setShowInputBox(false);
    }, [imageSrc]);

    return (
        <div className="container">
            <AudioPlayer src={Ambience01} />
            <div className='fullwidth'>
                <div className='content'>
                    <img key={imageSrc} className="screen reveal-image" src={imageSrc}/>
                        <div className="inputcontainer">
                            {animationFinished && showInputBox && ( // Render InputBox only if animation is finished and showInputBox is true
                                <InputBox
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                />
                            )}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default Screen;
