import React, { useState, useEffect } from 'react';
import './App.css'; // Import your CSS file



import Screen from './Screen';
import AudioPlayer from './AudioPlayer';
import Ambience01 from './assets/audio/Ambience01.ogg';
import Ambience02 from './assets/audio/Ambience02.ogg';

function App() {
    const [hasInteracted, setHasInteracted] = useState(() => {
        // Retrieve hasInteracted value from localStorage if available, or default to false
        return localStorage.getItem('hasInteracted') === 'true';
    });

    const [audioSrc, setAudioSrc] = useState(Ambience01); // Initialize audio src to Ambience01

    useEffect(() => {
        // Store the hasInteracted state in localStorage
        localStorage.setItem('hasInteracted', hasInteracted.toString());

        // Clear localStorage when the component is unmounted
        return () => {
            localStorage.removeItem('hasInteracted');
        };
    }, [hasInteracted]);

    // Listen for beforeunload event to clear localStorage when the page is refreshed or closed
    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('hasInteracted');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Function to update audio source
    const handleAudioSrcChange = (newSrc) => {
        setAudioSrc(newSrc);
    };

    return (
        <div className="App">
            <Screen setHasInteracted={setHasInteracted} hasInteracted={hasInteracted} handleAudioSrcChange={handleAudioSrcChange} />
            <AudioPlayer src={audioSrc} hasInteracted={hasInteracted} />

        </div>
    );
}

export default App;
