import { useState } from 'react';

const CallAPI = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (url, options = {}) => {
        setLoading(true);
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            setError(error);
            throw error;
        }
    };

    const sendRequest = async (url, options) => {
        try {
            const data = await fetchData(url, options);
            return data; // Return the parsed JSON response
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    return { loading, error, sendRequest };
};

export default CallAPI;