import React, { useRef, useEffect, useState } from 'react';

const InputBox = ({ value, onChange, onKeyPress, placeholder }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const inputElement = inputRef.current;

        inputElement.focus();

        const handleBlur = (event) => {
            event.preventDefault();
        };

        if (inputElement) {
            inputElement.addEventListener('blur', handleBlur);
        }

        const handleClick = (event) => {
            if (!inputElement.contains(event.target)) {
                inputElement.focus();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('blur', handleBlur);
            }
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (        
        <input
            ref={inputRef}
            id="inputbox"
            type="text"
            maxLength={79}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
            autoComplete="off"
            spellCheck="false"
        />
    );
};

export default InputBox;
